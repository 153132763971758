import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useState, useEffect } from 'react';

import AjaxRequest from "../../utils/AjaxRequest";
import UserActions from "../../actions/UserActions";
import { getThingId as id } from "../../utils/GlobalMethods";

export default function InfoPlaygroundDialog({ open, handleClose, handleOpenSnackbar, currentPlayground }) {
    const [timeZone, setTimeZone] = useState(currentPlayground.time_zone);
    const [timeZones, setTimeZones] = useState([]);

    useEffect(() => {
        AjaxRequest("/api/utils/timeZones", "GET")
            .then((data) => {
                setTimeZones(data.time_zones);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const onSave = () => {
        AjaxRequest(`/api/playground/${id(currentPlayground)}/timeZone`, "PUT", { name: currentPlayground.name, timeZone: timeZone })
            .then(() => {
                UserActions.getPlaygrounds();
                handleOpenSnackbar("Time zone updated");
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const changeTimeZone = ({ target }) => {
        setTimeZone(target.value);
    };

    const isInvalid = timeZone === currentPlayground.time_zone;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle >{"Collection info"}</DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column" spacing={2}>
                    <Grid item >
                        <Typography variant='subtitle2'>{'Name'}</Typography>
                        <Typography>{currentPlayground.name}</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant='subtitle2'>{'Number of things'}</Typography>
                        <Typography>{currentPlayground.things}</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant='subtitle2'>{'Created at'}</Typography>
                        <Typography>{moment.unix(currentPlayground.created_at).format("dddd, MMMM Do YYYY, HH:mm:ss")}</Typography>
                    </Grid>
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item >
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant='subtitle2'>{'Time zone'}</Typography>
                            </Grid>
                            <Grid item>
                                <FormControl variant='outlined' size='small' sx={{
                                    margin: theme => theme.spacing(2, 0, 2, 0),
                                    minWidth: '120px'
                                }}>
                                    <Select
                                        value={timeZone}
                                        onChange={changeTimeZone}
                                    >
                                        {timeZones.map((tz, i) =>
                                            <MenuItem value={tz} key={i}>
                                                {tz}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={onSave} disabled={isInvalid}>
                                    {'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="dark" onClick={handleClose} >
                    {"Close"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}