import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => (
    <Box sx={{
        alignItems: "center",
        backgroundColor: theme => theme.palette.secondary.main,
        display: "flex",
        flexGrow: 1,
        height: "100vh",
        justifyContent: "center",
        width: "100%"
    }}>
        <CircularProgress color="primary" size={80} />
    </Box>
);

export default Loading;