import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Logo from "../../assets/images/logo_on_white.svg"

function Step1() {
    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <img src={Logo} alt="ThingsDB logo" style={{
                    maxWidth: '450px',
                    minWidth: '300px'
                }} />
            </Grid>
            <Grid item>
                <Typography variant="h3">
                    {"Welcome to ThingsDB"}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center">
                    {"ThingsDB, a new and intuitive database designed with developers \
                    in mind, is now available! To allow you to get in touch with \
                    ThingsDB in a low-threshold way, we have developed this website. \
                    Here, you can set up a playground and start testing in no more \
                    than 10 seconds. And to make it even better, it's all free of charge!"}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Step1;