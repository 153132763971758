import Alert from '@mui/material/Alert';
import AssignmentIcon from "@mui/icons-material/Assessment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { useState } from "react";

import AjaxRequest from "../../utils/AjaxRequest";
import Firebase from "../Firebase";

const FlexWindow = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

export default function AccessTokenDialog({ open, handleClose, handleOpenSnackbar, currentPlayground }) {
    const [authorizing, setAuthorizing] = useState(false);
    const [securityCode, setSecurityCode] = useState("");
    const [verificationErrorState, setVerificationErrorState] = useState(false);
    const [verificationHelperText, setVerificationHelperText] = useState("");
    const [authorized, setAuthorized] = useState(false);
    const [token, setToken] = useState("");

    const sendSecurityCode = () => {
        const user = Firebase.auth.currentUser;
        AjaxRequest(`/api/user/${user.uid}/sendSecurityCode`, "POST")
            .then(() => {
                setAuthorizing(true);
                handleOpenSnackbar("Security code send");
            });
    }

    const getToken = () => {
        const user = Firebase.auth.currentUser;
        const username = currentPlayground.name;
        AjaxRequest(`/api/user/${user.uid}/getToken`, "POST", { username, securityCode })
            .then((data) => {
                setAuthorized(true);
                handleOpenSnackbar("Security code verified");
                setToken(data.token);
            })
            .catch(() => {
                setVerificationErrorState(true);
                setVerificationHelperText("The security code entered is incorrect or has expired");
            });
    }

    const handleChangeSecurityCode = ({ target }) => {
        setSecurityCode(target.value);
    }

    const close = () => {
        setAuthorizing(false);
        setSecurityCode("");
        setVerificationErrorState(false);
        setVerificationHelperText("");
        setAuthorized("");
        setToken("");
        handleClose();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(token);
        handleOpenSnackbar("Copied to clipboard");
    };

    const isInvalid = securityCode === "";

    const content = authorized ?
        <DialogContent dividers sx={{ padding: theme => theme.spacing(4) }}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <DialogContentText>
                        {"This access token can be used to communicate with your playground."}
                    </DialogContentText>
                </Grid>
                <Grid item>
                    <TextField
                        label="Token"
                        value={token}
                        variant="outlined"
                        sx={{ width: '300px' }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment >
                                    <Tooltip title="Copy to clipboard">
                                        <IconButton onClick={copyToClipboard}>
                                            <AssignmentIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item>
                    <Alert severity="warning">{"You should store your credentials in a secure location. It is important to keep your credentials confidential to protect your account."}</Alert>
                </Grid>
            </Grid>
        </DialogContent>
        : authorizing ?
            <FlexWindow dividers>
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3} >
                    <Grid item >
                        <Typography variant="h6">{"Enter security code"}</Typography>
                    </Grid>
                    <Grid item align="center">
                        <Typography >{"Please enter the security code we have just send to your email."}</Typography>
                    </Grid>
                    <Grid item >
                        <TextField
                            value={securityCode}
                            onChange={handleChangeSecurityCode}
                            variant="outlined"
                            placeholder="E.g. 123456"
                            inputProps={{
                                maxLength: 25
                            }}
                            type="text"
                            error={verificationErrorState}
                            helperText={verificationHelperText}
                        />
                    </Grid>
                    <Grid item >
                        <Button
                            variant="contained"
                            disabled={isInvalid}
                            onClick={getToken}
                        >
                            {"Verify"}
                        </Button>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2" align="center">
                            {`Didn't get the email? Try checking your spam folder or `}
                            <Link
                                component="button"
                                variant="subtitle2"
                                color="primary"
                                underline="none"
                                onClick={sendSecurityCode}
                            >
                                {"resend"}
                            </Link>
                            {" it."}
                        </Typography>
                    </Grid>
                </Grid>
            </FlexWindow> :
            <FlexWindow dividers >
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3} >
                    <Grid item >
                        <Typography variant="h6">
                            {"This page is restricted"}
                        </Typography>
                    </Grid>
                    <Grid item align="center">
                        <Typography >
                            {"Click on the button below and we will send you an email with a security code."}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Button
                            variant="contained"
                            onClick={sendSecurityCode}
                        >
                            {"Send security code"}
                        </Button>
                    </Grid>
                </Grid>
            </FlexWindow >;

    return (
        <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{"Access Token"}</DialogTitle>
            {content}
            <DialogActions>
                <Button color="dark" onClick={close} >
                    {"Close"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}