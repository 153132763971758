import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import BookIcon from '@mui/icons-material/Book';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Grid from "@mui/material/Grid";
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PropTypes from 'prop-types';
import StorageIcon from '@mui/icons-material/Storage';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { cloneElement, Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import * as LOCATIONS from '../../constants/locations';
import * as ROUTES from "../../constants/routes";
import AjaxRequest from '../../utils/AjaxRequest';
import NewsList from '../NewsList';
import CesbitLogo from "../../assets/images/cesbit-symbol.png";
import ThingsDBLogo from "../../assets/images/thingsdb_on_white_logo.png";
import UserActions from '../../actions/UserActions';
import { version } from '../../../package.json';
import { withAuthentication } from "../Session";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 2 : 0,
  });
}

const drawerWidth = 300;

const StyledNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0
  }
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  backgroundColor: theme.palette.background.lightBlue
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
}));

const StyledDiv = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center'
}));

function ResponsiveDrawer(props) {
  const { window, authUser } = props;
  const theme = useTheme();
  let location = useLocation();
  const year = new Date().getFullYear();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorElNewsList, setAnchorElNewsList] = useState(null);
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    AjaxRequest('https://api.github.com/repos/thingsdb/ThingsDB/releases', 'GET')
      .then((data) => {
        setReleases(data.slice(0, 10));
      });
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNewsListOpen = event => {
    setAnchorElNewsList(event.currentTarget);
    localStorage.setItem('lastRead', Math.round((Date.now() / 1000)));
  };

  const handleNewsListClose = () => {
    setAnchorElNewsList(null);
  };

  const isNewsListOpen = Boolean(anchorElNewsList);

  const paperStyles = {
    width: drawerWidth
  };

  const drawer = (
    <Fragment>
      <List sx={{
        marginTop: theme => theme.spacing(3),
        marginBottom: theme => theme.spacing(3)
      }}>
        <StyledListItem>
          <a href={"https://www.thingsdb.io"}>
            <img
              src={ThingsDBLogo}
              style={{ width: '125px' }}
              alt="ThingsDB logo"
            />
          </a>
        </StyledListItem>
        <StyledListItem>
          <Avatar alt="Profile picture" src={authUser.photoURL ? authUser.photoURL : null} sx={{
            margin: theme => theme.spacing(1),
            width: '125px',
            height: '125px'
          }}>
            {!authUser.photoURL ? <AccountCircleIcon /> : null}
          </Avatar>
        </StyledListItem>
        <StyledListItem>
          <Typography variant='subtitle2' noWrap color='textSecondary'>{authUser.email}</Typography>
        </StyledListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          selected={location.pathname === ROUTES.ACCOUNT + '/' + LOCATIONS.PLAYGROUNDS}
          component={RouterLink}
          to={{ pathname: ROUTES.ACCOUNT + '/' + LOCATIONS.PLAYGROUNDS }}
        >
          <ListItemIcon><StorageIcon /></ListItemIcon>
          <ListItemText
            primary={
              <Typography color={location.pathname !== ROUTES.ACCOUNT + '/' + LOCATIONS.PLAYGROUNDS ? 'textSecondary' : 'initial'}>
                {'My playgrounds'}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          selected={location.pathname === ROUTES.ACCOUNT + '/' + LOCATIONS.GETTING_STARTED}
          component={RouterLink}
          to={{ pathname: ROUTES.ACCOUNT + '/' + LOCATIONS.GETTING_STARTED }}
        >
          <ListItemIcon><BookIcon /></ListItemIcon>
          <ListItemText
            primary={
              <Typography color={location.pathname !== ROUTES.ACCOUNT + '/' + LOCATIONS.GETTING_STARTED ? 'textSecondary' : 'initial'}>
                {'Getting started'}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <List sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
      }}>
        <ListItem
          button
          onClick={UserActions.signOut}
        >
          <ListItemIcon><ExitToAppIcon color='error' /></ListItemIcon>
          <ListItemText
            primary={
              <Typography color='error'>
                {'Sign out'}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <Grid container justifyContent='center'>
            <Typography variant='subtitle2' sx={{ marginBottom: theme => theme.spacing(2) }}>
              {"Version: " + version}
            </Typography>
            <Grid container direction="row" alignItems="center" justifyContent='center'>
              <Box component='img' src={CesbitLogo} alt="Company logo" sx={{
                height: '25px',
                marginRight: theme => theme.spacing(1)
              }} />
              <Typography variant='caption'>
                {`© ${year}, Cesbit`}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Fragment>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const noNewReleases = releases.filter(r => (new Date(r.published_at).getTime() / 1000) > localStorage.getItem('lastRead')).length;

  return (
    <div style={{
      display: 'flex'
    }}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <StyledAppBar position='fixed'>
          <Toolbar>
            <StyledIconButton
              color='primary'
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </StyledIconButton>
            <div style={{ flexGrow: 1 }} />
            <IconButton color='primary' onClick={handleNewsListOpen}>
              <Badge badgeContent={noNewReleases} max={10} color='error'>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>
      <StyledNav aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            PaperProps={{
              sx: paperStyles
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            PaperProps={{
              sx: paperStyles
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </StyledNav>
      <Box component='main' sx={{
        flexGrow: 1,
        padding: theme => theme.spacing(5),
        backgroundColor: theme => theme.palette.background.lightBlue,
        minHeight: '100vh'
      }}>
        <StyledDiv />
        <Typography variant='h4' gutterBottom sx={{ paddingBottom: theme => theme.spacing(1) }}>
          {location.pathname === ROUTES.ACCOUNT + '/' + LOCATIONS.PLAYGROUNDS ? 'My playgrounds' : 'Getting started'}
        </Typography>
        <Divider sx={{
          marginBottom: theme => theme.spacing(6)
        }} />
        <Container disableGutters>
          {props.children}
        </Container>
      </Box>
      <NewsList
        open={isNewsListOpen}
        anchor={anchorElNewsList}
        closeList={handleNewsListClose}
        items={releases}
      />
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withAuthentication(ResponsiveDrawer);