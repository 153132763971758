import * as React from 'react';

import AuthUserContext from './context';
import Firebase from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
      };
    }

    checkProfilePic = (user) => {
      let firebasePhotoURL = user.photoURL;
      let providerPhotoURL = user.providerData[0].photoURL;

      if (firebasePhotoURL !== providerPhotoURL) {
        this.updateProfilePic(user, providerPhotoURL);
      }
    };

    updateProfilePic = async (user, photoURL) => {
      await user.updateProfile({ 'photoURL': photoURL });
      // After this point user's profile pic is updated
    }

    componentDidMount() {
      this.listener = Firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
          this.checkProfilePic(authUser);
        },
        () => {
          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component authUser={this.state.authUser} {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
