import Grid from "@mui/material/Grid";
import { Fragment, useState, useEffect } from "react";
import { withVlow } from "vlow";

import AccessTokenDialog from "./AccessTokenDialog";
import AjaxRequest from "../../utils/AjaxRequest";
import CreatePlaygroundDialog from "./CreatePlaygroundDialog";
import DeletePlaygroundDialog from "./DeletePlaygroundDialog";
import Firebase from "../Firebase";
import InfoPlaygroundDialog from "./InfoPlaygroundDialog";
import NewPlaygroundCard from "../PlaygroundCard/NewPlaygroundCard";
import PlaygroundCard from "../PlaygroundCard";
import SimpleSnackbar from "../Snackbar";
import UserActions from "../../actions/UserActions";
import UserStore from "../../stores/UserStore";
import WelcomeDialog from "../WelcomeDialog";

const GuiURL = process.env.REACT_APP_GUI_URL;

function PlaygroundsPage({ playgrounds }) {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openAccessTokenDialog, setOpenAccessTokenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [currentPlayground, setCurrentPlayground] = useState(null);

    useEffect(() => {
        UserActions.getUser();
        UserActions.getPlaygrounds();
        const user = Firebase.auth.currentUser;
        const metadata = user.metadata;
        const alreadySeen = localStorage.getItem(`welcomeDialogSeen-${user.uid}`);
        if (metadata.lastSignInTime === metadata.creationTime && !alreadySeen) {
            setOpenWelcomeDialog(true);
            localStorage.setItem(`welcomeDialogSeen-${user.uid}`, true);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            UserActions.getPlaygrounds();
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleClickOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleClickOpenDeleteDialog = (playground) => {
        setCurrentPlayground(playground);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setCurrentPlayground(null);
    };

    const handleClickOpenGUI = (playground) => {
        const user = Firebase.auth.currentUser;
        const username = playground.name;
        AjaxRequest(`/api/user/${user.uid}/getKey`, "POST", { username })
        .then((data) => {
            if(data.key){
                handleOpenSnackbar("Opened the GUI in a new tab");
                const url = `${GuiURL}?key=${data.key}`;
                window.open(url, '_blank');
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const handleClickOpenAccessTokenDialog = (playground) => {
        setCurrentPlayground(playground);
        setOpenAccessTokenDialog(true);
    };

    const handleCloseAccessTokenDialog = () => {
        setOpenAccessTokenDialog(false);
        setCurrentPlayground(null);
    };

    const handleClickOpenInfoDialog = (playground) => {
        setCurrentPlayground(playground);
        setOpenInfoDialog(true);
    };

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false);
        setCurrentPlayground(null);
    };

    const handleCloseWelcomeDialog = () => {
        setOpenWelcomeDialog(false);
    };

    const handleOpenSnackbar = (message) => {
        setOpenSnackbar(true);
        setSnackbarMessage(message);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
        setSnackbarMessage(null);
    };

    return (
        <Fragment>
            <Grid container spacing={5}>
                {playgrounds.map((playground, index) => (
                    <Grid item key={index}>
                        <PlaygroundCard
                            playground={playground}
                            onOpenGUI={handleClickOpenGUI}
                            onRetrieveAccessToken={handleClickOpenAccessTokenDialog}
                            onDelete={handleClickOpenDeleteDialog}
                            index={index}
                            onClick={handleClickOpenInfoDialog}
                        />
                    </Grid>
                ))}
                <Grid item>
                    <NewPlaygroundCard onClick={handleClickOpenCreateDialog} />
                </Grid>
            </Grid>
            <CreatePlaygroundDialog open={openCreateDialog} handleClose={handleCloseCreateDialog} handleOpenSnackbar={handleOpenSnackbar} />
            <AccessTokenDialog open={openAccessTokenDialog} handleClose={handleCloseAccessTokenDialog} handleOpenSnackbar={handleOpenSnackbar} currentPlayground={currentPlayground} />
            <DeletePlaygroundDialog open={openDeleteDialog} handleClose={handleCloseDeleteDialog} handleOpenSnackbar={handleOpenSnackbar} currentPlayground={currentPlayground} />
            {currentPlayground && <InfoPlaygroundDialog open={openInfoDialog} handleClose={handleCloseInfoDialog} handleOpenSnackbar={handleOpenSnackbar} currentPlayground={currentPlayground} />}
            <WelcomeDialog open={openWelcomeDialog} handleClose={handleCloseWelcomeDialog} />
            <SimpleSnackbar open={openSnackbar} onClose={handleCloseSnackbar} message={snackbarMessage} />
        </Fragment >
    );
}

export default withVlow({ store: UserStore, keys: ["playgrounds"] })(PlaygroundsPage);;