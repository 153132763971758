import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import ReactMarkdown from 'react-markdown'
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Fragment } from 'react';

function NewsList({ open, anchor, closeList, items }) {
    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onClose={closeList}
            PaperProps={{
                sx: {
                    zIndex: '1500px',
                    minWidth: '100px',
                    maxWidth: '325px'
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuList>
                <List sx={{
                    maxHeight: '250px',
                    overflow: 'auto'
                }}>
                    {items.map((item, i) => (
                        <div key={item.id}>
                            <ListItem
                                button
                                component='a'
                                href={item.html_url}
                                target='_blank'
                            >
                                <ListItemText
                                    primary={
                                        <Typography gutterBottom>
                                            {item.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Fragment>
                                            <Typography variant='body2' gutterBottom>
                                                <ReactMarkdown children={item.body} />
                                            </Typography>
                                            <Typography variant='caption'>{moment(item.published_at).format('MMMM D, YYYY')}</Typography>
                                        </Fragment>
                                    }
                                    sx={{
                                        wordWrap: 'break-word'
                                    }}
                                />
                            </ListItem>
                            {i !== (items.length - 1) ? <Divider sx={{
                                marginLeft: theme => theme.spacing(2),
                                marginRight: theme => theme.spacing(2)
                            }} /> : null}
                        </div>
                    ))}
                </List>
            </MenuList>
        </Popover>
    );
}

export default NewsList;