import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { Component } from 'react';

class SimpleSnackbar extends Component {
    render() {
        const { open, onClose, message } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={onClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={
                    <span id="client-snackbar" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <CheckCircleIcon sx={{
                            fontSize: '1.25rem',
                            marginRight: '5px'
                        }} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

export default SimpleSnackbar;