import { useEffect, useState } from "react";
import { Route } from 'react-router-dom';

import PlaygroundsPage from "../../components/PlaygroundsPage";
import GettingStartedPage from "../../components/GettingStartedPage";
import Loading from "../../components/Loading";
import UserStore from "../../stores/UserStore";
import { withAuthorization } from "../../components/Session";
import { withVlow } from "vlow";
import * as LOCATIONS from '../../constants/locations';
import * as ROUTES from '../../constants/routes';
import ResponsiveDrawer from '../../components/ResponsiveDrawer';

function Account() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 2000);
    }, []);

    if (loading) {
        return <Loading />;
    }
    return (
        <ResponsiveDrawer>
            <Route path={ROUTES.ACCOUNT + '/' + LOCATIONS.PLAYGROUNDS}>
                <PlaygroundsPage />
            </Route>
            <Route path={ROUTES.ACCOUNT + '/' + LOCATIONS.GETTING_STARTED}>
                <GettingStartedPage />
            </Route>
        </ResponsiveDrawer>
    );
}

export default withVlow(UserStore)(withAuthorization(Account));