import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import * as ROUTES from "./constants/routes";
import Account from "./routes/account";
import CookieBanner from "./components/CookieBanner";
import SignIn from "./routes/signin";
import { withAuthentication } from './components/Session';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3a5985"
      },
      secondary: {
        main: "#ffffff"
      },
      background: {
        default: grey[50],
        lightBlue: "#e6eefb"
      },
      blue: {
        light: "#89afe0",
        normal: "#618fca"
      },
      dark: {
        main: "#1e1f21"
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    props: {
      MuiPaper: {
        elevation: 2
      },
      MuiCard: {
        elevation: 2
      },
      MuiAppBar: {
        elevation: 2
      }
    }
  });

  theme.components = {
    // Style sheet name ⚛️
    MuiTableCell: {
      styleOverrides: {
        // Name of the rule
        root: {
          // Some CSS
          fontSize: theme.typography.pxToRem(13),
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: "hover"
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 2
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 2
      }
    },
    MuiPopover: {
      defaultProps: {
        elevation: 4
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <Switch>
          <Route path={ROUTES.ACCOUNT}>
            {/* Header tags for this route are not necessary as it is a 'private' route */}
            <Account />
          </Route>
          <Route path={ROUTES.SIGN_IN}>
            <Helmet>
              <title>{'ThingsDB | Sign in'}</title>
              <meta name="description" content="Sign in to your ThingsDB account." />
            </Helmet>
            <SignIn />
          </Route>
          <Route path={ROUTES.LANDING}>
            {/* Header tags for this route are not necessary as it is a 'private' route */}
            <Account />
          </Route>
        </Switch>
        <CookieBanner />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withAuthentication(App);
