import AutorenewIcon from '@mui/icons-material/Autorenew';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { withVlow } from "vlow";

import AjaxRequest from "../../utils/AjaxRequest";
import UserActions from "../../actions/UserActions";
import UserStore from "../../stores/UserStore";

function CreatePlaygroundDialog({ playgrounds, open, handleClose, handleOpenSnackbar }) {
    const [name, setName] = useState("");
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");

    const generateName = () => {
        AjaxRequest("/api/utils/randomName", "GET")
            .then((data) => setName(data.name))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        generateName();
    }, [open]);

    const onCreate = () => {
        AjaxRequest("/api/playground", "POST", { name: name })
            .then(() => {
                UserActions.getPlaygrounds();
                handleOpenSnackbar("Playground created");
                handleClose();
            })
            .catch((error) => {
                setError(true);
                setErrorText("This name is already in use");
            });
    };

    const isInvalid = name === '';


    if (playgrounds.length < 10) {
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{"Create Playground"}</DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6" gutterBottom>
                                {"Name"}
                            </Typography>
                            <OutlinedInput
                                type='text'
                                error={error}
                                readOnly
                                fullWidth
                                value={name}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Regenerate token" onClick={generateName}>
                                            <AutorenewIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {error && <Typography sx={{ color: theme => theme.palette.error.main }}>{errorText}</Typography>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="dark">
                        {"Cancel"}
                    </Button>
                    <Button onClick={onCreate} disabled={isInvalid}>
                        {"Create"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    else {
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{"Maximum number of playgrounds reached!"}</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        {"You have reached the maximum number of playgrounds."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="dark" onClick={handleClose} >
                        {"Close"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withVlow({ store: UserStore, keys: ["playgrounds"] })(CreatePlaygroundDialog);