import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Typography from '@mui/material/Typography';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styled } from '@mui/material/styles';

const Header = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export default function Go() {
    return (
        <Grid container spacing={5} direction="column">
            <Grid item >
                <Header variant="h4" gutterBottom>
                    {"Go"}
                </Header>
                <Typography>
                    {"This is an explanation of how you can communicate with ThingsDB through \
                the Go client. This client makes use of socket connections to communciate \
                with ThingsDB. For more information about the client, consult the official "}
                    <Link href="https://docs.thingsdb.net/v0/connect/go/">
                        {"ThingsDB documentation"}
                    </Link>
                    {"."}
                </Typography>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
                <Header variant="h6" gutterBottom>
                    {"Example"}
                </Header>
                <Typography>
                    {"Example using the Go client with token authentication:"}
                </Typography>
                <SyntaxHighlighter
                    style={docco}
                    language="go"
                    lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                    wrapLines={true}
                >
                    {`package main

import (
    "crypto/tls"
    "fmt"
    thingsdb "github.com/thingsdb/go-thingsdb"
)

func example(conn *thingsdb.Conn, res chan interface{}) {
    var data interface{}
    var err error

    if err := conn.Connect(); err != nil {
        res <- err
        return
    }

    defer conn.Close()

    // Replace <TOKEN> with your access token
    if err := conn.AuthToken("<TOKEN>"); err != nil {
        res <- err
        return
    }

    // Replace <PLAYGROUND> with the name of your playground
    if data, err = conn.Query("//<PLAYGROUND>", "1 + 1;", nil, 120); err != nil {
        res <- err
        return
    }

    res <- data
}

func main() {
    conf := &tls.Config{
        InsecureSkipVerify: false,
    }

    res := make(chan interface{})
    conn := thingsdb.NewConn("playground.thingsdb.net", 9400, conf)
    go example(conn, res)

    // Wait and print the response
    data := <-res
    fmt.Printf("%v\\n", data)
}`}
                </SyntaxHighlighter>
                <Typography>
                    {"Response:"}
                </Typography>
                <SyntaxHighlighter
                    style={docco}
                    language="bash"
                >
                    {"2"}
                </SyntaxHighlighter>
            </Grid >
        </Grid>
    );
}