import UserActions from '../actions/UserActions';

async function AjaxRequest(url, method, data) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data ? JSON.stringify(data) : null // body data type must match "Content-Type" header
    });
    if (response.ok) {
        if (response.status === 200) {
            return await response.json(); // parses JSON response into native JavaScript objects
        }
    }
    else {
        if (response.status === 401) {
            UserActions.signIn();
        }
        throw response.statusText;
    }
}

export default AjaxRequest;