import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GoogleLoginButton, GithubLoginButton } from 'react-social-login-buttons';
import { withVlow } from "vlow";

import Firebase from "../Firebase";
import UserStore from "../../stores/UserStore";

function SignInPage() {
    const signInWithGoogle = () => {
        Firebase.doSignInWithGoogle();
    };

    const signInWithGitHub = () => {
        Firebase.doSignInWithGitHub();
    };

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Paper sx={{ padding: theme => theme.spacing(5, 10, 5, 10) }}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item>
                        <Typography variant="h4">{"Sign in"}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <GoogleLoginButton
                                    size='40px'
                                    iconSize='20px'
                                    onClick={signInWithGoogle}
                                    align='center'
                                    style={{ fontSize: 14, width: 220, boxShadow: 'rgba(0, 0, 0, 0.5) 0px 1.25px 2.5px', fontWeight: 'bold', color: '#737373' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GithubLoginButton
                                    size='40px'
                                    iconSize='20px'
                                    onClick={signInWithGitHub}
                                    align='center'
                                    style={{ fontSize: 14, width: 220, boxShadow: 'rgba(0, 0, 0, 0.5) 0px 1.25px 2.5px', fontWeight: 'bold' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default withVlow(UserStore)(SignInPage);