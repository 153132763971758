import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { forwardRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function WelcomeDialog({ open, handleClose }) {
    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container sx={{
                height: "100%",
                alignItems: "center",
                display: "flex"
            }}>
                <Grid container direction="column" alignItems="center" spacing={4}>
                    <Grid item>
                        {activeStep === 0 && <Step1 />}
                        {activeStep === 1 && <Step2 />}
                        {activeStep === 2 && <Step3 />}
                        {activeStep === 3 && <Step4 />}
                    </Grid>
                    <Grid item sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                    }} >
                        <MobileStepper
                            variant="dots"
                            steps={4}
                            position="static"
                            activeStep={activeStep}
                            sx={{
                                maxWidth: '400px',
                                flexGrow: 1,
                                backgroundColor: theme => theme.palette.secondary.main
                            }}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={activeStep !== 3 ? handleNext : handleClose}
                                    disabled={activeStep === 4}
                                    color={activeStep !== 3 ? "default" : "primary"}
                                    variant={activeStep !== 3 ? "text" : "contained"}
                                >
                                    {activeStep !== 3 ? "Next" : "Get Started"}
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0} >
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                    {"Back"}
                                </Button>
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}