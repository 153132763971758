import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import AjaxRequest from "../../utils/AjaxRequest";
import UserActions from "../../actions/UserActions";
import { getThingId as id } from "../../utils/GlobalMethods";

export default function DeletePlaygroundDialog({ open, handleClose, handleOpenSnackbar, currentPlayground }) {
    const [playgroundName, setPlaygroundName] = useState("");
    const onDelete = () => {
        AjaxRequest(`/api/playground/${id(currentPlayground)}`, "DELETE", { name: currentPlayground.name })
            .then(() => {
                UserActions.getPlaygrounds();
                handleOpenSnackbar("Playground deleted");
                handleClose();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        setPlaygroundName('');
    }, [open]);

    const changePlaygroundName = ({ target }) => {
        setPlaygroundName(target.value);
    };

    if (currentPlayground) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: 532 }
                }}
            >
                <DialogTitle >{"Delete playground?"}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DialogContentText >
                                {"Are you sure you want to delete playground "}
                                <strong>
                                    {currentPlayground.name}
                                </strong>
                                {"?"}
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <DialogContentText >
                                {"Please type in the name of the playground to confirm."}
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <TextField value={playgroundName} variant="outlined" onChange={changePlaygroundName} sx={{ width: '484px' }} label="Name" />
                        </Grid>
                        <Grid item>
                            <Alert severity="warning">
                                {"This action CANNOT be undone! Proceeding will lead to data loss."}
                            </Alert>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="dark" onClick={handleClose} >
                        {"Close"}
                    </Button>
                    <Button onClick={onDelete} color="error" disabled={currentPlayground.name !== playgroundName} >
                        {"Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    else {
        return null;
    }
}