import Vlow from "vlow";

import AjaxRequest from "../utils/AjaxRequest";
import Firebase from "../components/Firebase";
import UserActions from "../actions/UserActions";

const INITIAL_STATE = {
    user: null,
    playgrounds: []
};

class UserStore extends Vlow.Store {

    constructor() {
        super(UserActions);
        this.state = { ...INITIAL_STATE };
    }

    onSignIn() {
        const user = Firebase.auth.currentUser;
        user.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
                // Send token to the backend
                AjaxRequest("/api/user/signIn", "POST", { firebaseIdToken: idToken, email: user.email })
                    .then((data) => {
                        this.setState({ user: data.user });
                        this.onGetPlaygrounds();
                    })
                    .catch(() => {
                        Firebase.doSignOut();
                        this.setState({ ...INITIAL_STATE });
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onGetUser() {
        const user = Firebase.auth.currentUser;
        AjaxRequest(`/api/user/${user.uid}`, "GET")
            .then((data) =>
                this.setState({ user: data.user })
            )
            .catch((error) => console.error(error));
    }

    onGetPlaygrounds() {
        const user = Firebase.auth.currentUser;
        AjaxRequest(`/api/user/${user.uid}/playgrounds`, "GET")
            .then((data) =>
                this.setState({ playgrounds: data.playgrounds })
            )
            .catch((error) => console.error(error));
    }

    onSignOut() {
        Firebase.doSignOut();
        AjaxRequest("/api/user/signOut", "GET")
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            });
    }
}

export default UserStore;