import AppBar from "@mui/material/AppBar";
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink } from "react-router-dom";

import ThingsDBLogo from "../../assets/images/thingsdb_on_white_logo.png";

function NavBar() {
    return (
        <AppBar position="fixed" color="secondary">
            <Toolbar>
                <a href={"https://www.thingsdb.io"}>
                    <img
                        src={ThingsDBLogo}
                        style={{ height: '40px' }}
                        alt="ThingsDB logo"
                    />
                </a>
            </Toolbar>
        </AppBar>
    );
}

export default NavBar;