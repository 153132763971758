import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const Root = styled(Grid)(({ theme }) => ({
    position: "fixed",
    left: 0,
    bottom: 0,
    backgroundColor: "#000",
    color: "#fff",
    opacity: "85%",
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 5, 2, 5)
    },
    zIndex: 1501
}));

const StyledButton = styled(Button)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up('sm')]: {
        justifyContent: "right",
    }
}));


function CookieBanner() {
    const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem("thingsdb.cookiesAllowed"));

    const AcceptCookies = () => {
        // Store Cookies consent
        localStorage.setItem("thingsdb.cookiesAllowed", true);
        setCookiesAccepted(true);
    };

    if (!cookiesAccepted) {
        return (
            <Root container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={10}>
                    <Typography>
                        {'This site uses cookies which are required for access restrictions and analytical purposes. By using our site, you consent to our use of cookies.'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <StyledButton
                        color="secondary"
                        variant="outlined"
                        onClick={AcceptCookies}
                    >
                        {"Accept Cookies"}
                    </StyledButton>
                </Grid>
            </Root>
        );
    }
    else {
        return null;
    }
}

export default CookieBanner;