import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Fragment, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { withVlow } from "vlow";

import * as ROUTES from "../../constants/routes";
import * as LOCATIONS from '../../constants/locations';
import Firebase from "../../components/Firebase";
import Loading from "../../components/Loading";
import NavBar from "../../components/NavBar";
import SignInPage from "../../components/SignInPage";
import UserActions from "../../actions/UserActions";
import UserStore from "../../stores/UserStore";

const Toolbar = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar
}));

function SignIn() {
    let history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Firebase.auth.getRedirectResult()
            .then(() => {
                UserActions.signIn();
                history.push(ROUTES.ACCOUNT + '/' + LOCATIONS.PLAYGROUNDS);
            })
            .catch(() => {
                setLoading(false);
            });
    });

    if (loading) {
        return <Loading />;
    }
    return (
        <Fragment>
            <NavBar />
            <Toolbar />
            <Container sx={{ height: '75vh' }}>
                <SignInPage />
            </Container>
            <Box sx={{
                backgroundColor: theme => theme.palette.secondary.main
            }}>
            </Box>
        </Fragment>
    );
}

export default withVlow(UserStore)(SignIn);