import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import Go from "./Go";
import HTTPAPI from "./HTTPAPI";
import Python from "./Python";
import ThingsGUI from "./ThingsGUI";

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
    }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: 'auto'
}));

export default function GettingStartedPaper() {
    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Paper>
            <Tabs
                centered
                variant="fullWidth"
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <StyledTab label="HTTP API" />
                <StyledTab label="Things GUI" />
                <StyledTab label="Python" />
                <StyledTab label="Go" />
            </Tabs>
            <Root>
                {tab === 0 && <HTTPAPI />}
                {tab === 1 && <ThingsGUI />}
                {tab === 2 && <Python />}
                {tab === 3 && <Go />}
            </Root>
        </Paper>
    );
}