import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Finish from "../../assets/images/finish.png"

function Step1() {
    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <img src={Finish} alt="Tutorial finished" style={{
                    maxWidth: '419px',
                    minWidth: '224px'
                }} />
            </Grid>
            <Grid item>
                <Typography variant="h3">
                    {"Good luck!"}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center">
                    {"That was it! Now you should have all the information to \
                    set up and use your first playground."}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Step1;