import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

export default function PlaygroundCard({ onClick }) {
    return (
        <Card sx={{
            width: '345px',
            height: '310px',
            display: 'flex',
            background: 'rgba(255, 255, 255, 0.33)'
        }}>
            <CardActionArea onClick={onClick}>
                <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
                    <Grid item xs={12}>
                        <AddIcon fontSize='large' color='primary' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' color='primary'>
                            {'New playground'}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
}