import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Typography from '@mui/material/Typography';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styled } from '@mui/material/styles';

const Header = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export default function HTTPAPI() {
    return (
        <Grid container spacing={5} direction="column">
            <Grid item >
                <Header variant="h4" gutterBottom>
                    {"HTTP API"}
                </Header>
                <Typography>
                    {"This is an explanation of how you can communicate with ThingsDB through \
                the HTTP API. In the examples below, we use "}
                    <Link href="https://curl.haxx.se/">{"cURL"}</Link>
                    {" to achieve this. cURL is a computer software project providing a library (libcurl) \
                and command-line tool (curl) for transferring data using various \
                network protocols. For more information about the HTTP API, consult the official "}
                    <Link href="https://docs.thingsdb.net/v0/connect/http-api/">
                        {"ThingsDB documentation"}
                    </Link>
                    {"."}
                </Typography>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
                <Header variant="h6" gutterBottom>
                    {"Example"}
                </Header>
                <Typography>
                    {"Example using cURL with token authentication (Replace <PLAYGROUND> with the name of your playground and <TOKEN> with your access token):"}
                </Typography>
                <SyntaxHighlighter
                    style={docco}
                    language="bash"
                    lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                    wrapLines={true}
                >
                    {`curl --location --request POST 'https://playground.thingsdb.net//<PLAYGROUND>' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer <TOKEN>' \\
--data-raw '{
    "type": "query",
    "code": "1 + 1;"
}'`}
                </SyntaxHighlighter>
                <Typography>
                    {"Response:"}
                </Typography>
                <SyntaxHighlighter
                    style={docco}
                    language="bash"
                >
                    {"2"}
                </SyntaxHighlighter>
            </Grid >
        </Grid>
    );
}