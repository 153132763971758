import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Playground from "../../assets/images/playground.png"

function Step1() {
    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <img src={Playground} alt="ThingsDB Playground" style={{
                    maxWidth: '407px',
                    minWidth: '149px'
                }} />
            </Grid>
            <Grid item>
                <Typography variant="h3">
                    {"Step 1: Create a playground"}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center">
                    {"You can create up to 10 playgrounds to experience all the benefits \
                    of ThingsDB in combination with free hosting. To create \
                    a playground, simply press the `Create` button in your account \
                    page. Select a suitable name and create your playground. That's it!"}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Step1;