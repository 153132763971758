import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import ThingsGUI1 from "../../assets/images/Screenshot from 2020-01-22 17-03-42.png";
import ThingsGUI2 from "../../assets/images/Screenshot from 2020-01-22 16-18-33.png";

const Header = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const Image = styled('img')(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
    minWidth: '200px',
    borderRadius: '5px',
    maxHeight: '300px'
}));

export default function ThingsGUI() {
    return (
        <Grid container spacing={5} direction="column">
            <Grid item >
                <Header variant="h4" gutterBottom>
                    {"ThingsGUI"}
                </Header>
                <Typography>
                    {"This is an explanation of how you can communicate with ThingsDB through \
                ThingsGUI. ThingsGUI is a free and open source administration tool for ThingsDB, \
                that makes use of socket connections to communciate with ThingsDB. \
                For more information about ThingsGUI, consult the official "}
                    <Link href="https://github.com/thingsdb/ThingsGUI#readme">
                        {"GitHub repository"}
                    </Link>
                    {"."}
                </Typography>
            </Grid>
            <Grid item >
                <Header variant="h5" gutterBottom>
                    {"Authentication"}
                </Header>
                <Image src={ThingsGUI1} alt="ThingsGUI - Sign in" />
                <Typography>
                    {"To authenticatie yourself in ThingsGUI simply select 'with token', \
                    enter 'playground.thingsdb.net:9400' as socket address and type your token. \
                    Make sure to enable 'Secure Connection (TLS)'."}
                </Typography>
            </Grid>
            <Grid item >
                <Header variant="h5" gutterBottom>
                    {"Quering"}
                </Header>
                <Image src={ThingsGUI2} alt="ThingsGUI - Query" />
                <Typography>
                    {"Once you are connected to your playground. You can simply select the \
                    editor tab and start querying your playground. It's as easy as that."}
                </Typography>
            </Grid>
        </Grid>
    );
}