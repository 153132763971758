import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { styled } from '@mui/material/styles';

import Planet1 from '../../assets/images/daniel-olah-_j6wbAlQ1ow-unsplash.jpg';
import Planet10 from '../../assets/images/daniel-olah-vg-K6YoOIpo-unsplash.jpg';
import Planet2 from '../../assets/images/daniel-olah-4i6Fsw5kl5M-unsplash.jpg';
import Planet3 from '../../assets/images/daniel-olah-dFA5XycbhoQ-unsplash.jpg';
import Planet4 from '../../assets/images/daniel-olah-fazB8Al_LnE-unsplash.jpg';
import Planet5 from '../../assets/images/daniel-olah-fTyBR0bkDF8-unsplash.jpg';
import Planet6 from '../../assets/images/daniel-olah-gEta6dbzFy0-unsplash.jpg';
import Planet7 from '../../assets/images/daniel-olah-IAJNzZhDADQ-unsplash.jpg';
import Planet8 from '../../assets/images/daniel-olah-UpatVsW2kcg-unsplash.jpg';
import Planet9 from '../../assets/images/daniel-olah-UrdBlloc3oY-unsplash.jpg';

const StyledTypography = styled(Typography)(({ theme }) => ({
    display: 'flex'
}));

const StyledBox = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(1)
}));

export default function PlaygroundCard({ playground, onOpenGUI, onRetrieveAccessToken, onDelete, onClick, index }) {
    const planets = [Planet1, Planet2, Planet3, Planet4, Planet5, Planet6, Planet7, Planet8, Planet9, Planet10];

    return (
        <Card sx={{
            width: '345px',
            height: '310px'
        }}>
            <CardActionArea onClick={() => onClick(playground)}>
                <CardMedia
                    sx={{ height: '140px' }}
                    image={planets[index]}
                    title="Planet"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {playground.name}
                    </Typography>
                    <StyledTypography variant="body2" color="textSecondary" gutterBottom component='div' >
                        <Box>
                            {'Number of things: '}
                        </Box>
                        <StyledBox fontWeight="fontWeightBold">
                            {playground.things}
                        </StyledBox>
                    </StyledTypography>
                    <StyledTypography variant="body2" color="textSecondary" gutterBottom component='div' >
                        <Box>
                            {'Created: '}
                        </Box>
                        <StyledBox fontWeight="fontWeightBold">
                            {moment.unix(playground.created_at).fromNow()}
                        </StyledBox>
                    </StyledTypography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" onClick={() => onRetrieveAccessToken(playground)}>
                    {'Access token'}
                </Button>
                <Button size="small" onClick={() => onOpenGUI(playground)}>
                    {'Open GUI'}
                </Button>
                <Button size="small" color="error" onClick={() => onDelete(playground)}>
                    {'Delete'}
                </Button>
            </CardActions>
        </Card >
    );
}