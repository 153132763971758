import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Communication from "../../assets/images/communication.png"

function Step1() {
    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <img src={Communication} alt="ThingsDB Communication" style={{
                    maxWidth: '429px',
                    minWidth: '166px',
                }} />
            </Grid>
            <Grid item>
                <Typography variant="h3">
                    {"Step 2: Communicate with ThingsDB"}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center">
                    {"After you have created a playground, you can start communicating \
                    with ThingsDB. Choose one of the supported communication options \
                    that you prefer and start using your playground. These options \
                    are described in the `Getting Started` in your account page."}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Step1;