import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Typography from '@mui/material/Typography';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styled } from '@mui/material/styles';

const Header = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export default function Python() {
    return (
        <Grid container spacing={5} direction="column">
            <Grid item >
                <Header variant="h4" gutterBottom>
                    {"Python"}
                </Header>
                <Typography>
                    {"This is an explanation of how you can communicate with ThingsDB through \
                the Python client. This client makes use of socket connections to communciate \
                with ThingsDB. For more information about the client, consult the official "}
                    <Link href="https://docs.thingsdb.net/v0/connect/python/">
                        {"ThingsDB documentation"}
                    </Link>
                    {"."}
                </Typography>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
                <Header variant="h6" gutterBottom>
                    {"Example"}
                </Header>
                <Typography>
                    {"Example using the Python client with token authentication:"}
                </Typography>
                <SyntaxHighlighter
                    style={docco}
                    language="python"
                    lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                    wrapLines={true}
                >
                    {`import asyncio
from thingsdb.client import Client

async def example():
    client = Client(ssl=True)
    await client.connect('playground.thingsdb.net', 9400)

    try:
        # Replace <TOKEN> with your access token
        await client.authenticate('<TOKEN>')

        # Replace <PLAYGROUND> with the name of your playground
        client.set_default_scope('//<PLAYGROUND>')
        print(await client.query('1 + 1;'))

    finally:
        client.close()
        await client.wait_closed()

asyncio.get_event_loop().run_until_complete(example())`}
                </SyntaxHighlighter>
                <Typography>
                    {"Response:"}
                </Typography>
                <SyntaxHighlighter
                    style={docco}
                    language="bash"
                >
                    {"2"}
                </SyntaxHighlighter>
            </Grid >
        </Grid>
    );
}